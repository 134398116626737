module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"autosavingsurvey.com","short_name":"autosavingsurvey.com","start_url":"/","version":"0.0.2","permissions":["gcm","storage","notifications"],"gcm_sender_id":"395966986145","gcm_user_visible_only":true,"display":"standalone","icon":"/opt/atlassian/pipelines/agent/build/data/sites/insurance/autosavingsurvey.com/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"01ddca75a96dc184c942eedb0d695670"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
